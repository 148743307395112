<template>
  <div>
    <!-- 공지사항 제목 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">공지사항 제목</span>
        </div>
      </div>
      <!-- 제목 -->
      <div class="info-content-wrap">
        <input-text
          v-model="noticeData.title"
          :use-counter="true"
          :limit-nm="100"
          wp-cls="w-100"
        />
        <input-check v-model="noticeData.top" style="margin-top: 18px"
          >상단 고정</input-check
        >
      </div>
    </div>
    <!-- 공지사항 상세내용 -->
    <div class="info-container" style="margin-top: 10px">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">공지사항 상세내용</span>
        </div>
      </div>
      <!-- 제목 -->
      <div class="info-content-wrap">
        <editor-common :content.sync="noticeData.content" class="w-100" />
      </div>
    </div>
    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          style="margin-right: 8px"
          @click="goNoticeCompanyList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!noticeData.title || !noticeData.content"
          @click="addData"
          >{{ isEdit ? "수정" : "추가" }}</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NoticeCompanyAdd",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      isEdit: false,
      noticeData: {
        title: "",
        content: "",
        top: false,
      },
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    if (this.$route.params.id) {
      this.isEdit = true;
      await this.getNoticeData();
    }
  },

  //   beforeRouteLeave(to, from, next) {
  //     this.toRoute.path = to.fullPath;
  //     this.toRoute.meta = to.meta.id;
  //     if (this.isReset) {
  //       next();
  //     } else {
  //       next(false);
  //       if (!this.isReset) {
  //         this.onPageReset();
  //       }
  //     }
  //   },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.isEdit = true;
        this.getNoticeData();
      } else {
        this.isEdit = false;
        this.noticeData = {
          title: "",
          content: "",
          top: false,
        };
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 공지사항 상세 조회 ***/
    async getNoticeData() {
      const rs = await this.$axios({
        url: "/admin/notice/partner/data",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.noticeData = rs.resultData;
        this.noticeData.top = this.noticeData.top === "true";
      } else {
        console.log("공지사항 조회 실패");
      }
    },

    /** @ 공지사항 등록 ***/
    async addData() {
      const rs = await this.$axios({
        url: "/admin/notice/partner/update",
        params: this.noticeData,
      });
      if (rs.result === "SUC") {
        this.goNoticeCompanyList();
      } else {
        console.log("공지사항 등록 실패");
      }
    },

    goNoticeCompanyList() {
      if (this.isEdit) {
        this.$router.back();
      } else {
        this.$router.push({
          path: `/notice/company/list`,
          meta: { id: "15-1-1" },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.info-content-wrap {
  padding: 16px 24px;
}
</style>
